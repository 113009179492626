import { prop } from 'ramda'
import { useQuery } from '@tanstack/react-query'

import { useAuth } from '@elvia/elvid-provider'
import { useKundeportalApi, invalidateQueries } from './ApiProvider'
import { ClientCustomerDto, Role } from './kundeportal-api'
import { useCustomerParams } from '@elvia/minside-routing'

export const useCustomers = <T = ClientCustomerDto[]>(
  select?: (data: ClientCustomerDto[]) => T
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const kundePortalApi = useKundeportalApi()
  const { isAuthenticated, user } = useAuth()

  const fetchCustomers = () =>
    kundePortalApi.web
      .customerList({
        PageSize: 5000,
      })
      .then(response => response.data)

  const { data, ...rest } = useQuery<ClientCustomerDto[], Error, T>({
    queryKey: ['useCustomers', user?.access_token],
    queryFn: fetchCustomers,
    enabled: isAuthenticated,
    select,
  })

  return {
    data: data || ([] as T),
    ...rest,
  }
}

export const invalidateUseCustomers = (): ReturnType<
  typeof invalidateQueries
> => {
  return invalidateQueries({ queryKey: ['useCustomers'] })
}

export const useSelectedCustomer = <T = ClientCustomerDto>(
  select?: (data: ClientCustomerDto) => T
): ReturnType<typeof useQuery<ClientCustomerDto, Error, T>> => {
  const kundePortalApi = useKundeportalApi()
  const { customerId } = useCustomerParams()
  const { isAuthenticated } = useAuth()

  const fetchCustomerById = () => {
    if (!customerId) throw new Error('No customer id')

    return kundePortalApi.customer.customerDetail(customerId).then(prop('data'))
  }

  return useQuery<ClientCustomerDto, Error, T>({
    queryKey: ['useSelectedCustomer', customerId],
    queryFn: fetchCustomerById,
    enabled: isAuthenticated && !!customerId,
    select,
  })
}

interface IGetCustomerRole {
  canEdit: boolean
  canView: boolean
}

export const getCustomerRole = (data: ClientCustomerDto): IGetCustomerRole => {
  const canEdit =
    data.role === Role.Owner ||
    data.role === Role.CompanyReader ||
    data.role === Role.CompanyOwner

  return {
    canEdit,
    canView: canEdit || data.role === Role.CustomerService,
  }
}
