export const customerIdToken = 'customerId'
export const contractIdToken = 'contractId'
export const accessRequestIdToken = 'accessRequestId'

export const routes = {
  myPage: '/minside',
  customerPage: `/minside/:${customerIdToken}`,
  meteringPoints: `/minside/:${customerIdToken}/malere`,
  meteringPoint: `/minside/:${customerIdToken}/malere/:${contractIdToken}`,
  customerInfo: `/minside/:${customerIdToken}/kundeinfo`,
  notifications: `/minside/:${customerIdToken}/varslinger`,
  notification: `/minside/:${customerIdToken}/varslinger/:${contractIdToken}`,
  accessOverview: `/minside/:${customerIdToken}/tilganger`,
  hanPort: `/minside/:${customerIdToken}/hanport`,
  hanPortSuccess: `/minside/:${customerIdToken}/hanport/vellykket`,
  invoices: `/minside/:${customerIdToken}/fakturaer`,
  invoice: `/minside/:${customerIdToken}/fakturaer/:${contractIdToken}`,
  productionSharings: `/minside/:${customerIdToken}/produksjonsdeling`,
  productionSharing: `/minside/:${customerIdToken}/produksjonsdeling/:${contractIdToken}`,
  tariff: `/minside/:${customerIdToken}/nettleie/:${contractIdToken}`,
  earthFault: `/minside/:${customerIdToken}/jordfeil/:${contractIdToken}`,
  accessRequest: '/minside/tilgang',
  accessRequestPrivate: '/minside/tilgang/privat',
  accessRequestPrivateSuccess: '/minside/tilgang/privat/vellykket',
  accessRequestCorporate: '/minside/tilgang/bedrift',
  accessRequestCorporateSuccess: '/minside/tilgang/bedrift/vellykket',
  accessRequestApproval: `/minside/tilgang/:${accessRequestIdToken}`,
  accessRequestApprovalSuccess: `/minside/tilgang/:${accessRequestIdToken}/vellykket`,
}

export function getRelativeUrl(url: string): string {
  const urlParts = url.split('/')

  if (!urlParts.length) {
    return ''
  }

  const lastPart = urlParts.pop() || ''

  if (lastPart.startsWith(':')) {
    return `${urlParts.pop()}/${lastPart}`
  }

  return lastPart
}

export function getUrlWithParams(
  url: string,
  ...params: (string | number | undefined)[]
): string {
  if (params.includes(undefined)) {
    return routes.myPage
  }
  const urlParams = url.split('/').filter(part => part.startsWith(':'))

  if (urlParams.length !== params.length) {
    throw new Error(
      'Number of provided params to getUrlWithParams does not match with required url params.'
    )
  }

  return url
    .split('/')
    .map(part => {
      if (part.startsWith(':')) {
        return params.shift()
      }
      return part
    })
    .join('/')
    .concat('/')
}
