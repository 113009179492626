import axios, { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { mergeDeepRight } from 'ramda'

dayjs.extend(utc)

export interface FormState {
  [fieldLabel: string]: string
}

export interface Form {
  formValue: {
    source: string
    formType: string
    emailsOfReceivers: string[]
    emailOfSender: string
    subjectToCustomer: string
    subjectToCustomerService: string
    htmlContent: string
    jsonData: FormState
    data?: FormState
  }
  files?: File[]
}

const baseUrl = process.env.KUNDEPORTAL_API_URL

const getOptions = () => ({
  mode: 'cors',
  cache: 'no-cache',
  crossDomain: true,
  headers: { Pragma: 'no-cache' },
})

export const postForm = async (form: Form): Promise<AxiosResponse<unknown>> => {
  const path = '/form'
  const url = `${baseUrl}${path}`
  const options = getOptions()
  const formData = new FormData()
  formData.append('formValue', JSON.stringify(form.formValue))
  if (form.files && form.files.length > 0) {
    form.files.forEach(file => formData.append('files', file, file.name))
  }

  const res = await axios
    .post(url, formData, mergeDeepRight(options, {}))
    .catch(e => {
      window?.appInsights?.trackException(e, 'postForm', {
        url,
        formData: JSON.stringify(formData),
      })
      throw new Error(e)
    })

  return res
}
