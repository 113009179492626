import { Location, WindowLocation, useMatch } from '@reach/router'
import React, {
  ComponentType,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

type ContextType = {
  customerId: string | null
  contractId: string | null
}

const CustomerParamsContext = createContext<ContextType>({
  customerId: null,
  contractId: null,
})

interface Props {
  location: WindowLocation
}

const getContractId = (): null | {
  [param: string]: string
  uri: string
  path: string
} => {
  const subPathsMain = ['minside', 'customer']
  const subPaths = [
    'malere',
    'fakturaer',
    'nettleie',
    'varslinger',
    'produksjonsdeling',
    'jordfeil',
    'contract',
  ]
  return subPathsMain.reduce(
    (acc, subPathMain) =>
      subPaths.reduce(
        (acc, subPath) =>
          useMatch(`/${subPathMain}/:customerId/${subPath}/:contractId/*`) ||
          acc,
        null as null | {
          [param: string]: string
          uri: string
          path: string
        }
      ) || acc,
    null as null | {
      [param: string]: string
      uri: string
      path: string
    }
  )
}

const getCustomerId = (): null | {
  [param: string]: string
  uri: string
  path: string
} => {
  const subPaths = ['minside', 'customer']
  return subPaths.reduce(
    (acc, subPath) => useMatch(`/${subPath}/:customerId/*`) || acc,
    null as null | {
      [param: string]: string
      uri: string
      path: string
    }
  )
}

const ParamsProvider: ComponentType<PropsWithChildren<Props>> = ({
  children,
  location,
}) => {
  const [customerId, setCustomerId] = useState<string | null>(null)
  const [contractId, setContractId] = useState<string | null>(null)

  const customerIdPathData = getCustomerId()
  const contractIdPathData = getContractId()

  useEffect(() => {
    const customerIdFromPath = customerIdPathData?.customerId || ''

    if (customerIdFromPath === '') {
      setCustomerId('')
    }

    if (customerIdFromPath === customerId || customerIdFromPath === 'tilgang')
      return

    setCustomerId(customerIdFromPath)
  }, [location.pathname, customerId, customerIdPathData?.customerId])

  useEffect(() => {
    const contractIdFromPath = contractIdPathData?.contractId || ''

    if (contractIdFromPath === '') {
      setContractId('')
    }
    if (contractIdFromPath === contractId) return

    setContractId(contractIdFromPath)
  }, [contractId, location.pathname, contractIdPathData?.contractId])

  return (
    <CustomerParamsContext.Provider value={{ customerId, contractId }}>
      {children}
    </CustomerParamsContext.Provider>
  )
}

export const CustomerParamsProvider: ComponentType<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Location>
      {({ location }) => (
        <ParamsProvider location={location}>{children}</ParamsProvider>
      )}
    </Location>
  )
}

export const useCustomerParams = (): ContextType => {
  const context = useContext(CustomerParamsContext)
  if (context === undefined) {
    throw new Error(
      'useCustomerParams must be used within a CustomerParamsProvider'
    )
  }
  return context
}
